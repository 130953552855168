<template>
	<section id="selfdxElearning" class="selfdx-section display-flex" :class="{ 'is-active': isVisible }" ref="viewport">
		<div class="container">
			<div class="selfdx-section-header">
				<h3 class="selfdx-section-header__title">역량강화를 위한 <span class="color-orange">e러닝 추천</span></h3>
				<p class="selfdx-section-header__desc">상권, 플랫폼 등 판로진출에 대한 온라인 강의를 들어보세요. 다양한 강의를 통해 튼튼한 기본기를 갖출 수 있습니다.</p>
				<div class="selfdx-section-header__buttons">
					<a href="/educationpre" target="_blank" class="button-default is-medium is-rounded is-secondary">전체 맛보기 강좌 보기</a>
				</div>
			</div>
			<div class="selfdx-section-content">
				<ul class="card-list">
					<li v-for="(item, idx) in diagnELearning.slice(0, 2)" :key="idx" class="card-item">
						<div class="card-item__image">
							<img :src="`https://d24x8kvt0el7da.cloudfront.net/${item.lvlCourseThumbnail}`" alt="" />
						</div>
						<div class="card-item-body">
							<span class="card-item__title"> {{ item.lvlCourseNm }}</span>
							<span class="card-item__desc"> {{ item.lvlCategoryNm }} | {{ getCourseTypeDcd(item.lvlType) }}</span>
							<div class="buttons">
								<button type="button" class="button-default is-small is-light is-rounded" @click="clickPreview(item.lvlCourseId)">강의 맛보기</button>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SelfDxResultElearning',
	components: {},
	props: {
		diagnELearning: {
			type: Array,
			default: [],
		},
	},
	data: () => ({
		isVisible: false,
	}),
	mounted() {
		this.$nextTick(() => {
			this.initializeIntrsObsrv();
		});
	},
	methods: {
		initializeIntrsObsrv() {
			const io = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach(async (entry) => {
						// 감지대상이 교차영역에 진입 할 경우
						if (entry.isIntersecting) {
							this.isVisible = true;
							// 교차영역에 진입한 후 감지대상을 관찰 중지
							observer.unobserve(entry.target);
						} else {
							this.isVisible = false;
						}
					});
				},
				{
					rootMargin: '0px',
					threshold: 0.1,
				}
			);
			const element = this.$refs.viewport;
			if (element) {
				io.observe(element);
			}
		},
		clickPreview(courseId) {
			window.open(`https://kodma.getsmart.co.kr/course/${courseId}/player/preview`, '새창', 'width=800, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=yes, encrypted-media');
		},
		getCourseTypeDcd(val) {
			if (!val) return '';
			const dcdVals = [
				{ courseTypeDcd: '1012001', courseType: '이러닝' },
				{ courseTypeDcd: '1012002', courseType: '전화어학' },
				{ courseTypeDcd: '1012003', courseType: '집합교육' },
				{ courseTypeDcd: '1012004', courseType: '북러닝' },
				{ courseTypeDcd: '1012005', courseType: '소셜' },
				{ courseTypeDcd: '1012006', courseType: '마이크로러닝' },
				{ courseTypeDcd: '1012007', courseType: '블렌디드' },
			];
			const find = dcdVals.find((dcdVal) => dcdVal.courseTypeDcd == val);
			if (find) {
				return find.courseType;
			} else {
				return '';
			}
		},
	},
};
</script>
